<script>
  import { project, translations } from '$lib/services/store';

  export let operator = null;
  export let focus = "casino";

  let termsHTML = null

  $: {
    if (!operator || !operator?.bonus?.[focus]?.[$project.settings.market]?.tc) {
      termsHTML = null
      if ($project.key === 'cf' && $project.settings.market === 'se') {
        termsHTML = `Reklamlänk | 18+ | <a href=${ operator?.name_lower ? "/spela/" + operator?.name_lower + "/villkor" : null } target="_blank" rel="nofollow noreferrer noopener">Regler &amp; villkor gäller</a> | Spela ansvarsfullt | <a href="https://www.stodlinjen.se" target="_blank" rel="nofollow noreferrer noopener">stodlinjen.se</a>`
      } else if ($project.key === 'cf-ca') {
        termsHTML = `Affiliate link | 19+ | T&Cs Apply | Play responsible | This offer is not available for players residing in Ontario`
      } else {
        termsHTML = $translations["New customers. 18+. Gamble responsibly."][$project.settings.market]
      }
    }
    if ($project.settings.lang === 'sv'
      && operator?.bonus?.[focus]?.[$project.settings.market]?.tc) {
      termsHTML = operator?.bonus?.[focus]?.[$project.settings.market]?.tc
        .replace("Regler & villkor gäller", `<a href='/spela/${operator.name_lower}/villkor' target="_blank" rel="nofollow">Regler & villkor gäller</a>`)
        .replace("stodlinjen.se", `<a href='https://www.stodlinjen.se/' target="_blank" rel="nofollow">stodlinjen.se</a>`)
        .replace("Stodlinjen.se", `<a href='https://www.stodlinjen.se/' target="_blank" rel="nofollow">stodlinjen.se</a>`)
        .replace("spelpaus.se", `<a href='https://www.spelpaus.se' target='_blank' rel='nofollow'>spelpaus.se</a>`)
        .replace("Spelpaus.se", `<a href='https://www.spelpaus.se' target='_blank' rel='nofollow'>spelpaus.se</a>`)
        .replace("Fullständiga Regler & Villkor gäller", `<a href='/spela/${operator.name_lower}/villkor' target="_blank" rel="nofollow">Fullständiga Regler & Villkor gäller</a>`)
    }
  }
</script>

{#if termsHTML}
  {@html termsHTML}
{/if}