<script>
	import { fly } from 'svelte/transition';
  import Alert from '$lib/main/components/Alert.svelte';
  import Modal from '$lib/main/components/Modal.svelte';

  export let message = { success: null, display: "" };
  export let name = 'Item';
  export let remove = null;

  export let toggleRemove = false;

  // TODO: this can't possibly be the best way to introduce a delay!?
  //       should for sure have a look at re-working this one..
  $: {
    if (message?.display) {
      // console.log(message.display)
      setTimeout( function() {
        message.display = null
      }, 7000)
    }
  }
</script>

<slot />

{#if message?.display}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="message"
    in:fly={{ x: 50, duration: 200, delay: 200 }}
    out:fly={{ x: 50, duration: 200 }}
    on:click={() => message.display = null}>
    <Alert type={message.success ? 'success' : 'danger'}>{message.display}</Alert>
  </div>
{/if}

<Modal bind:toggleModal={toggleRemove}>
  <svelte:fragment slot="header">Remove {name}</svelte:fragment>
  <p>Do you want to remove <code>{name}</code>?</p>
  <button on:click={() => {remove(); toggleRemove = !toggleRemove}}>Remove</button>
  <button on:click={() => {toggleRemove = !toggleRemove}}>Cancel</button>
</Modal>