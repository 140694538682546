<script>
  import { project, postData, projectToplists, orgOperators, translations } from "$lib/services/store";
  import { page } from "$app/stores";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Icons from '$lib/main/components/Icons.svelte';
  import Rating from '$lib/admin/components/blocks/helpers/Rating.svelte';
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Heading from '$lib/admin/components/blocks/helpers/Heading.svelte';
  import ToplistRow from '$lib/admin/components/blocks/helpers/ToplistRow.svelte';
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import Links from '$lib/admin/components/blocks/blocks/Links.svelte';
  import CurrencyAmount from '$lib/admin/components/blocks/helpers/CurrencyAmount.svelte';

  let showLinks = false;
  let showMoreCasinos = false;
  const id = Math.random().toString(16);

  export let props = null;
  export let adminView = false;

  let streamingToplist = [
    {
      "name": "C More",
      "name_lower": "c-more",
      "prosStream": [
        "Registrera dig & välj C More Sport",
        "Från 349 kr per mån",
        "Streama all sport!"
      ]
    },
    {
      "name": "Viaplay",
      "name_lower": "viaplay",
      "prosStream": [
        "Registrera dig & välj Viaplay Sport",
        "349 kr per mån",
        "Streama all sport!"
      ]
    }
  ]
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      type="select"
      label="Toplist type"
      id={`type-${id}`}
      on:change
      bind:value={props.toplistType}>
      <option value="table">Table</option>
      <option value="cards">Cards</option>
      <option value="list">List</option>
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      type="select"
      label="Toplist topic"
      id={`topic-${id}`}
      on:change
      bind:value={props.toplistFocus}>
      <option value="casino">Casino Bonus</option>
      <option value="sport">Sports Bonus</option>
      <option value="freespins">Freespins</option>
      <option value="mindeposit">Min. deposit</option>
      <option value="slots">Slots</option>
      <option value="lotto">Lotto</option>
      <option value="streaming">Streaming</option>
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      type="select"
      label="Toplist data"
      id={`data-${id}`}
      bind:value={props.toplistId}>
      {#if $projectToplists}
        <option value={null}>None</option>
        {#each $projectToplists as item, i}
          <option value={item.id}>{item.name}</option>
        {/each}
      {/if}
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Toplist length"
      id={`length-${id}`}
      type="number"
      min="1"
      max="20"
      on:keyup
      bind:value={props.toplistLength} />
  </Group>
{:else}
  {#if props.toplistType == "table"}
    {@const toplist = $projectToplists?.find(x => x.id === props.toplistId)?.toplist || []}
    <Block blockClass="block-toplist" {props}>
      <div class="toplist-holder">
        <Heading {props} />

        <!-- TODO: create helper function? -->
        {#if props.toplistNavBar}
          <Links
            props={{
              "linksType": "toplistSort",
              "links": $project.menu?.toplistMenu
            }}
            bind:showLinks />
          <Links
            props={{
              "linksType": "toplistSort",
              "links": $project.menu?.toplistMenu
            }}
            bind:showLinks />
        {/if}

        <div class={`toplist ${props.toplistFocus}`}
          class:show-full={showMoreCasinos}>
          {#if props.toplistId
            && $projectToplists
            && $projectToplists.length > 0
            && $orgOperators}
            {#each toplist as operatorId, listIndex}
              {@const operator = $orgOperators.find(x => x.id === operatorId)}
              <ToplistRow {props} {operator} {listIndex} {showMoreCasinos}/>
            {/each}
          {:else if props.toplistLength == 1
            && props.toplistOperatorId
            && $orgOperators}
            <ToplistRow {props} operator={$orgOperators.find(x => x.id === props.toplistOperatorId)} />
          {/if}
        </div>

        <!-- TODO: create helper function? can use a regular Button component -->
        {#if props.toplistLoadMore
          && props.toplistLength < toplist.length}
          <div class="grid">
            <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
              <button class="load-more" on:click={() => { showMoreCasinos = !showMoreCasinos }}>
                {#if !showMoreCasinos}
                  <Icons name="rotate-cw" strokeWidth="3" /><span><Translate text="Load more casinos" /></span>
                {:else}
                  <Icons name="chevron-up" strokeWidth="3" /><span><Translate text="View less casinos" /></span>
                {/if}
              </button>
            </div>
          </div>
        {/if}

        {#if props.toplistLinkUrl}
          <div class="grid">
            <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
              <a href={ props.toplistLinkUrl } class="read-more" on:click={() => { showMoreCasinos = !showMoreCasinos }}>
                <span>{ props.toplistLinkAnchor }</span>
              </a>
            </div>
          </div>
        {/if}
      </div>
    </Block>
  {:else if props.toplistType == "cards"}
    <Block blockClass={"block-toplist cards " + props.toplistFocus}  {props}>
      <div class="toplist-holder">
        <Heading {props} />

        {#if props.toplistFocus === 'streaming'}
          <div class="toplist">
            {#each streamingToplist as stream, i}
              <div class="toplist-item">
                <div class="toplist-container">
                  <div class="toplist-img">
                    <img loading="lazy" alt={`Stream sport hos ${stream.name}`} src={`${$project.data.imageFolders.logos}/${stream.name_lower}-featured.png?auto=format`}>
                  </div>
                  <span>Streama Fotboll hos {stream.name}</span>
                  <ol>
                    {#each stream.prosStream as pro}
                      <li>{pro}</li>
                    {/each}
                  </ol>
                  <a class="toplist-cta" href={`/streama/${stream.name_lower}`} rel="nofollow">Streama nu</a>
                </div>
              </div>
            {/each}
          </div>
        {:else}
          <div class="toplist">
            {#if props.toplistId && $projectToplists && $projectToplists?.length > 0 && $orgOperators}
              {@const toplist = $projectToplists.find(x => x.id === props.toplistId)?.toplist.filter(i => i !== $postData.operator_id)}
              {#each toplist as operatorId, i}
                {#if i < props.toplistLength || showMoreCasinos}
                  {@const operator = $orgOperators.find(x => x.id === operatorId )}
                  <div class="toplist-item">
                    <div class="toplist-container">
                      <div class="toplist-img { "_" + operator["name_lower"] }">
                        <a class="title review" href={ ($project.data?.categoryPages?.reviews.slug ? "/" + $project.data?.categoryPages.reviews.slug : "") + "/" + operator.name_lower}>
                          <span>{ operator["name"] }</span>
                        </a>
                        <a class="img" href={`/${$project.settings.redirectUrl}/${operator.name_lower}`} target="_blank" rel="nofollow noreferrer noopener">
                          <img loading="lazy" alt={operator["name"]} src={ $project.data?.imageFolders?.operators + operator["name_lower"] + ".png?q=80&auto=format" }>
                        </a>
                        <div class="toplist-rating">
                          <Rating {operator} />
                        </div>
                      </div>
                      <!-- TODO: include system for campaigns -->
                      {#if 2 > 3}
                        <div class="toplist-campaign">
                          <span class="label label-info pull-left" style="background-color: #2CA5E4;border-radius: 7px;margin-right: 10px;font-weight:400;">
                            <span class="glyphicon glyphicon-bullhorn" aria-hidden="true"></span>
                          </span>
                          <p><b>LeoJackpot</b> - Leo Vegas egen jackpot är nu uppe i över 135 miljoner kr! <a href="https://casinofeber.se/spela/leo-vegas/747" target="_blank" rel="nofollow noreferrer">Läs mer hos Leo Vegas</a></p>
                        </div>
                      {/if}
                      <div class="toplist-bonus">
                        <div class="bonus-full">
                          {#if operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount && operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount}
                            <span class="bonus-details"><Translate text="Up to" /> <span class="bonus-amount"><CurrencyAmount amount={operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount} /></span> bonus + <span class="free-spins">{operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount}</span> <Translate text="Free spins" /></span>
                          {:else if operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount}
                          <span class="bonus-details"><Translate text="Up to" /> <span class="bonus-amount"><CurrencyAmount amount={operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount} /></span> bonus</span>
                          {:else if operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount}
                          <span class="bonus-details"><Translate text="Up to" /> <span class="free-spins">{operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount}</span> <Translate text="Free spins" /></span>
                          {:else}
                            { operator?.rating?.[$project.key]?.[$project.settings.market].prosCasino[0]}
                          {/if}
                        </div>
                      </div>
                      <div class="toplist-cta">
                        <a href={`/${$project.settings.redirectUrl}/${operator.name_lower}`} target="_blank" rel="nofollow noreferrer noopener" class="visit">
                          <span>Till casinot</span>
                          <span>Registrering utan krångel</span>
                        </a>
                      </div>
                    </div>
                    <div class="toplist-terms">
                      Reklamlänk | 18+ | <a href={`/${$project.settings.redirectUrl}/${operator.name_lower}/villkor`} target="_blank" rel="nofollow noreferrer">Regler &amp; villkor gäller</a> | Spela ansvarsfullt | <a href="https://www.stodlinjen.se" target="_blank" rel="nofollow noreferrer noopener">stodlinjen.se</a>
                    </div>
                  </div>
                {/if}
              {/each}
            {/if}
          </div>
        {/if}
        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
            {#if props.toplistButtonAnchor && props.toplistButtonUrl}
              <a class="read-more" href={props.toplistButtonUrl}><span>{props.toplistButtonAnchor}</span></a>
            {/if}
          </div>
        </div>
      </div>
    </Block>
  {:else if props.toplistType == "list"}
    <Block blockClass="block-toplist list" {props}>
      <Heading {props} />
      <ul>
        {#if props.toplistId && $projectToplists && $projectToplists?.length > 0 && $orgOperators}
          {#each $projectToplists?.find(x => x.id === props.toplistId)?.toplist || [] as operatorId, i}
            {@const operator = $orgOperators.find(x => x.id === operatorId)}
            <li>
              <a class={"_" + operator.name_lower + " logo"} href="/">
                <img loading="lazy" alt={ operator.name } src={ $project.data?.imageFolders.operators + operator["name_lower"] + ".png?q=80&auto=format" }>
              </a>
              <h4><a class="logo" href="/">{ operator.name }</a></h4>
              <div class="bonus">{ operator.bonus?.[props.toplistFocus]?.[$project.settings.market]?.["short"] ?? "No bonus"}</div>
              <a class="visit" href="/"><Translate text="Visit" /></a>
            </li>
          {/each}
        {/if}
      </ul>
      {#if props.toplistLinkUrl}
        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
            <a href={ props.toplistLinkUrl } class="read-more" on:click={() => { showMoreCasinos = !showMoreCasinos }}>
              <span>{ props.toplistLinkAnchor }</span>
            </a>
          </div>
        </div>
      {/if}
    </Block>
  {:else}
    <p>No toplist chosen..</p>
  {/if}
{/if}