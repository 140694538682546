<script>
  import { project, translations } from "$lib/services/store";
  import { page } from "$app/stores"
  import Icons from '$lib/main/components/Icons.svelte';
  import Rating from '$lib/admin/components/blocks/helpers/Rating.svelte';
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import Ribbon from '$lib/admin/components/blocks/helpers/Ribbon.svelte';
  import Terms from '$lib/admin/components/blocks/helpers/Terms.svelte';
  import CurrencyAmount from '$lib/admin/components/blocks/helpers/CurrencyAmount.svelte';

  export let props = null;
  export let operator = null;
  export let listIndex = null;
  export let showMoreCasinos = null

  let reviewLink = `/${$project.data.categoryPages.reviews.slug}/${operator?.name_lower}` ?? ""
  let ctaLink = `/${$project.settings.redirectUrl}/${operator?.name_lower}` ?? ""

  $: {
    if (operator?.name_lower === 'miljonlotteriet') {
      reviewLink = `/spela/${operator?.name_lower}`
      ctaLink = `/spela/${operator?.name_lower}`
    } else if ($project.settings.market === 'no') {
      reviewLink = `/spela/${operator?.name_lower}/no`
      ctaLink = `/spela/${operator?.name_lower}/no`
    } else if ($page.url.pathname === `/${$project.data.categoryPages.reviews.slug}/${operator?.name_lower}`) {
      reviewLink = `/${$project.settings.redirectUrl}/${operator?.name_lower}` ?? ""
    } else {
      reviewLink = `/${$project.data.categoryPages.reviews.slug}/${operator?.name_lower}` ?? ""
      ctaLink = `/${$project.settings.redirectUrl}/${operator?.name_lower}` ?? ""
    }
  }

  // TODO: add a toplistRowType variable to differentiate later?
</script>

{#if operator && !operator.rating?.[$project.key]?.[$project.settings.market]?.hide}
  <!-- TODO: needed this #if statement as browsing between pages which uses this component sometimes makes it crash in the unload/load period..  -->
  {#if listIndex + 1 <= Number(props.toplistLength) || showMoreCasinos}
    <div class="toplist-item" class:hide={listIndex + 1 > Number(props.toplistLength)}>
      <div class="toplist-container">
        <Ribbon {operator} />
        <div class="toplist-img { "_" + operator["name_lower"] }">
          <a class="title"
          href={reviewLink}
          rel={ reviewLink.includes($project.settings.redirectUrl) ? "nofollow noreferrer" : ""}
          target={ reviewLink.includes($project.settings.redirectUrl) ? "_blank" : ""}>
            <span>{ operator["name"] }</span>
          </a>
          <a class="img" href={ctaLink} rel="nofollow noreferrer">
            <img loading={ listIndex > 5 ? "lazy" : "eager" } alt={operator["name"]} src={ $project.data?.imageFolders?.operators + operator["name_lower"] + ".png?w=200&q=80&auto=format" }>
          </a>
          <Rating {operator} />
        </div>

        {#if operator?.rating?.[$project.key]?.[$project.settings.market]?.campaign}
          {@const campaign = operator?.rating?.[$project.key]?.[$project.settings.market]?.campaign}
          <div class="toplist-campaign">
            <div class="stamp">
              <Icons name="gift" strokeWidth="2" color="#fff" />
            </div>
            <div class="campaign-text">
              <p><b>{ campaign.title }</b> - { campaign.description }</p>
            </div>
            <a href={ campaign.link } target="_blank" rel="nofollow noreferrer">{ campaign.anchor }</a>
          </div>
        {/if}

        <div class={ "toplist-bonus " + props.toplistFocus + " _" + operator["name_lower"] } style={ "background-color: rgba(var(--_" + operator["name_lower"] + "), 0.15)" }>
          {#if props.toplistFocus === 'casino'
            || props.toplistFocus === 'mindeposit'
            || props.toplistFocus === 'freespins'}
            <div class="amount">
              {#if operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount}
                <span><CurrencyAmount amount={operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount} /></span> {operator?.bonus?.casino?.[$project.settings.market]?.bonus_type ?? "bonus"}
              {:else if operator?.bonus?.casino?.[$project.settings.market]?.amount}
                <span>{(operator?.bonus?.casino[$project.settings.market]?.currency ? operator?.bonus?.casino[$project.settings.market]?.currency + " " : "") + operator?.bonus?.casino[$project.settings.market]?.amount}</span> bonus
              {:else if operator?.bonus?.casino?.[$project.settings.market]?.bonus_fallback}
                {@html operator?.bonus?.casino?.[$project.settings.market]?.bonus_fallback}
              {:else}
                <span class="no-bonus"><Translate text="No" /></span> Bonus
              {/if}
            </div>

            <div class="freespins" class:no-free-spins={!operator?.bonus?.casino?.[$project.settings.market]?.["freespins_amount"] && !operator?.bonus?.casino?.[$project.settings.market]?.fallbacks?.["freespins_amount"]}>
              {#if operator?.bonus?.casino?.[$project.settings.market]?.["freespins_amount"]}
                <span>{operator?.bonus?.casino[$project.settings.market]?.["freespins_amount"]}</span> { ((operator?.bonus?.casino[$project.settings.market]?.["freespins_type"] === undefined) || operator?.bonus?.casino[$project.settings.market]?.["freespins_type"] === "") ? "Free spins" : operator?.bonus?.casino[$project.settings.market]?.["freespins_type"] }
              {:else if operator?.bonus?.casino?.[$project.settings.market]?.["freespins_fallback"]}
                {@html operator.bonus.casino[$project.settings.market]["freespins_fallback"]}
              {:else}
                <span class="no-free-spins"><Translate text="No" /></span> free spins
              {/if}
            </div>
          {:else if props.toplistFocus == 'sport'}
            <div class="amount">
              {#if operator?.bonus?.sports?.[$project.settings.market]?.bonus_amount}
                <span><CurrencyAmount amount={operator?.bonus?.sports?.[$project.settings.market]?.bonus_amount} /></span>
                {#if operator?.bonus?.sports?.[$project.settings.market]?.bonus_type
                  && operator?.bonus?.sports?.[$project.settings.market]?.bonus_type != ""}
                  {operator?.bonus?.sports?.[$project.settings.market]?.bonus_type}
                {:else}
                  <Translate text="Oddsbonus" />
                {/if}
              {:else if operator?.bonus?.sports?.[$project.settings.market]?.bonus_fallback}
                {@html operator?.bonus?.sports?.[$project.settings.market]?.bonus_fallback}
              {:else}
                <span class="no-bonus"><Translate text="No" /></span>
                <Translate text="Oddsbonus" />
              {/if}
            </div>

            <div class="turnover">
              {#if operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"] === "?"}
                <span class="no-bonus">?</span>
                <Translate text="Wagering req." />
              {:else if Number(operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"]) === 0}
                <Translate text="No turnover" />
              {:else if operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"]}
                <span class="sports-turnover">{operator?.bonus?.sports?.[$project.settings.market]?.["bonus_turnover"]} x</span> <Translate text="Wagering req." />
              {/if}
            </div>
          {:else if props.toplistFocus == 'lotto'}
            <div class="lotto">
              <Icons name="gift" strokeWidth="2" color="#2ca5e4" />
              <span>{operator?.bonus?.lotto?.[$project.settings.market]?.bonus}</span>
            </div>
          {/if}

          {#if props.toplistFocus === 'mindeposit'}
            <div class="mindeposit">
              {#if operator.data.depositLimits?.min[$project.settings.market]}
                <span>{operator.data?.depositLimits?.min[$project.settings.market]}</span>
                <Translate text="Minimum deposit" />
              {/if}
            </div>
          {:else if props.toplistFocus === 'freespins'}
            <div class="freespins-nodeposit">
              {#if operator.bonus?.casino?.[$project.settings.market]?.["freespins-no-deposit"]}
                <span>{operator.bonus.casino[$project.settings.market]["freespins-no-deposit"]}</span>
                <Translate text="FS no deposit" />
              {:else}
                <span><Translate text="No" /></span>
                <Translate text="FS no deposit" />
              {/if}
            </div>

            <div class="freespins-turnover">
              {#if operator.bonus?.casino?.[$project.settings.market]?.["freespins_turnover"] || operator.bonus?.casino?.[$project.settings.market]?.["freespins_turnover"] === 0}
                {#if Number(operator.bonus.casino[$project.settings.market]["freespins_turnover"]) === 0}
                  {@html `${$translations["Without"][$project.settings.lang]}<br>${$translations["Turnover"][$project.settings.lang]}`}
                {:else}
                  <span>{operator.bonus.casino[$project.settings.market]["freespins_turnover"]} x</span>
                  <Translate text="Wagering req." />
                {/if}
              {:else}
                <span><Translate text="No" /></span>
                <Translate text="Wagering req." />
              {/if}
            </div>
          {/if}

          <!-- {#if operator.licenses?.includes($project?.data?.mainLicense)}
            <div class="license">
              <img src="//cdn.casinofeber.se/pics/casinoinfo-svenskt.png?w=22&amp;h=22&amp;q=100&amp;auto=format" loading="lazy" alt="Svensk licens">
              <span><Translate text="Casino has english license" lang={$project?.settings?.market} /></span>
            </div>
          {:else if operator.countries?.includes($project?.settings?.market)}
            <div class="license">
              <img src={ $project.data?.imageFolders?.regions + $project?.settings?.market + ".svg" } width="15px" height="15px" loading="lazy" alt={ $translations["Casino open for english players"][$project.settings.market] }>
              <span><Translate text="Casino open for english players" lang={$project?.settings?.market} /></span>
            </div>
          {/if} -->
        </div>

        <div class={ "toplist-features " + props.toplistFocus + " _" + operator["name_lower"] } style={ "background-color: rgba(var(--_" + operator["name_lower"] + "), 0.3)" }>
          <ul>
            <li >
              <img loading="lazy" src={ $project.data?.imageFolders?.icons + "spelinspektionen" + ".png?h=54&q=100&auto=format" } alt={ `${operator["name"]} har licens fra Spelinspektionen` }>
              <span>Licens</span>
            </li>
            <li>
              <img loading="lazy" src={ $project.data?.imageFolders?.icons + "bankid" + ".png?h=54&q=100&auto=format" } alt={ `${operator["name"]} har BankID` }>
              <span>BankID</span>
            </li>
            <li class:not={ !operator?.data?.deposit?.[$project.settings.market]?.includes("Swish")}>
              <img loading="lazy" src={ $project.data?.imageFolders?.icons + "swish" + ".png?h=54&q=100&auto=format" } alt={ `${operator["name"]} har Swish` }>
              <span>Swish</span>
            </li>
            <li class:not={ !operator?.data?.deposit?.[$project.settings.market]?.includes("Trustly")}>
              <img loading="lazy" src={ $project.data?.imageFolders?.icons + "trustly" + ".png?h=54&q=100&auto=format" } alt={ `${operator["name"]} har Trustly` }>
              <span>Trustly</span>
            </li>
          </ul>
        </div>

        <ul class="toplist-pros">
          {#if operator?.rating?.[$project.key]?.[$project.settings.market]?.prosCasino && props.toplistFocus == 'casino'}
            {#each operator.rating[$project.key][$project.settings.market].prosCasino as pro}
              {#if pro}
                <li>{pro}</li>
              {/if}
            {/each}
          {:else if operator?.rating?.[$project.key]?.[$project.settings.market]?.prosSports && props.toplistFocus == 'sport'}
            {#each operator.rating[$project.key][$project.settings.market].prosSports as pro, i}
              {#if pro && pro != ""}
                <li>{pro}</li>
              {:else}
                <li>{operator?.rating?.[$project.key]?.[$project.settings.market]?.prosCasino?.[i]}</li>
              {/if}
            {/each}
          {/if}
          {#if operator.licenses?.includes($project?.data?.mainLicense)}
            <li class="license"><Translate text="Casino has english license" lang={$project?.settings?.market}/></li>
          {:else if operator.countries?.includes($project?.settings?.market)}
            <li class="license open"><Translate text="Casino open for english players" lang={$project?.settings?.market}/></li>
          {/if}
        </ul>

        <div class="toplist-cta">
          <a href={`/${$project.settings.redirectUrl}/${operator.name_lower}`} target="_blank" rel="nofollow noreferrer noopener" class="visit">
            <span><Translate text={"Visit" + (props.toplistFocus == "casino" ? " casino" : "") } /></span>
            <span><Translate text="Proceed to" /> { operator["name"] }</span>
          </a>
          {#if $page.url.pathname != `/${$project.data.categoryPages.reviews.slug}/${operator.name_lower}` && reviewLink}
            <a href={reviewLink} class="review">{ operator["name"] }</a>
          {/if}
        </div>
      </div>

      <div class="toplist-terms">
        <Terms {operator} />
      </div>
    </div>
  {/if}
{/if}