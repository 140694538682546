<script>
  export let label = undefined
  export let id = undefined
  export let placeholder = undefined
  export let disabled = undefined
  export let readonly = undefined
  export let addClass = ""
  export let required = undefined
  export let value = undefined
  export let type = "text"
  export let checked = false
  export let max = null
  export let min = null
  export let files = []

  function typeAction(node) {
    node.type = type
  }
</script>

{#if label}
  <label class={addClass} for={id}>{label}</label>
{/if}

<!--
  TODO: textarea doesn't work for some reason, unable to bind:value
-->
{#if type == "textarea"}
  <textarea {id} name={id} {placeholder} class={addClass} {disabled} {readonly} bind:value on:keyup />
{:else if type == "select"}
  <select {id} name={id} class={addClass} {disabled} {readonly} {required} bind:value on:change>
    <slot />
  </select>
{:else if type == "checkbox"}
  <input {id} name={id} type="checkbox" class={addClass} {disabled} {readonly} bind:value on:checked bind:checked on:change />
{:else if type == "file"}
  <input {id} name={id} type="file" class={addClass} {disabled} {readonly} use:typeAction {placeholder} bind:value on:change bind:files />
{:else if type == "range"}
  <input {id} type="range" {placeholder} class={addClass} {max} {min} {readonly} bind:value on:change />
{:else if type == "hidden"}
  <input {id} type="hidden" name={id} use:typeAction {placeholder} class={addClass} {disabled} {readonly} {required} bind:value on:keyup/>
{:else}
  <input {id} name={id} use:typeAction {placeholder} class={addClass} {disabled} {readonly} {required} bind:value on:keyup/>
{/if}